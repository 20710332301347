import axios from 'axios';
import { API_URL } from '../url';

export const getBlogs = async () => {
    try {
        const res = await axios.get(API_URL + 'project');

        return res.data;
    } catch (error) {
        return { success: false };
    }
};
