// DetailsPage.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'; // Import Link for navigation
import { API_URL } from '../url';

const DetailsPage = () => {
    const [employeeList, setEmployeeList] = useState([]);
    console.log('first', employeeList);
    const fetchEmployeeData = async () => {
        try {
            const response = await axios.get(`${API_URL}/employee`);
            setEmployeeList(response.data?.data);
        } catch (error) {
            console.error('Error fetching employee data:', error);
        }
    };

    useEffect(() => {
        // Fetch employee data from the server

        fetchEmployeeData();
    }, []);

    const deleteEmployee = async (id) => {
        try {
            const response = await axios.delete(`${API_URL}/employee/${id}`);
            fetchEmployeeData();
            alert('employee Deleted');
        } catch (error) {
            console.error('Error fetching employee data:', error);
        }
    };

    return (
        <div className="container" style={{ margin: '20px auto 20px  0px' }}>
            <h2>Employee Details</h2>
            <table className="table table-responsive">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Location</th>
                        <th>Post</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {employeeList?.map((employee) => (
                        <tr key={employee?._id}>
                            <td>{employee?.name}</td>
                            <td>{employee?.location}</td>
                            <td>{employee?.post}</td>
                            <td>
                                {/* Link to the Edit page */}
                                <Link
                                    to={`/edit/${employee?._id}`}
                                    state={{ employee: employee }}
                                    className="btn btn-warning">
                                    Edit
                                </Link>
                                &nbsp;&nbsp;
                                {/* Add a delete button (you need to implement the delete functionality) */}
                                <button
                                    className="btn btn-danger"
                                    onClick={(e) =>
                                        window.confirm(
                                            `Are you sure you want to delete this employee details?`,
                                        ) && deleteEmployee(employee?._id)
                                    }>
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Link to the Add page */}
            <Link to="/add-employee" className="btn btn-success">
                Add Employee
            </Link>
        </div>
    );
};

export default DetailsPage;
