import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../url';

import { useNavigate, useLocation } from 'react-router-dom';

const EmployeeAdd = ({ match, employeeData }) => {
    const navigate = useNavigate();
    let location = useLocation();
    const data = location?.state?.employee;
    const isEditing = match?.params?.id ? true : false;
    const initialFormData = {
        name: data?.name ? data?.name : '',
        location: data?.location ? data?.location : '',
        post: data?.post ? data?.post : '',
        profileImage: '',
    };

    const [formData, setFormData] = useState(initialFormData);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (e) => {
        if (e.target.type === 'file') {
            setFormData({ ...formData, [e.target.name]: e.target.files[0] });
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    };

    const handleSubmit = async () => {
        if (!formData?.name) {
            return setErrorMessage('Please Enter Employee Name');
        }
        if (!formData?.location) {
            return setErrorMessage('Please Enter location');
        }
        if (!formData?.post) {
            return setErrorMessage('Please Enter Employee post');
        }
        // if (!formData?.profileImage) {
        //     return setErrorMessage('Please select your image');
        // }

        try {
            setErrorMessage('');
            setLoading(true);
            // Create FormData object
            const formDataObject = new FormData();
            Object.entries(formData).forEach(([key, value]) => {
                formDataObject.append(key, value);
            });
            // Determine the API endpoint based on whether it's an edit or add
            const apiEndpoint = data
                ? `${API_URL}/employee/${data._id}` // Replace with your actual edit endpoint
                : `${API_URL}/employee`; // Replace with your actual add endpoint

            const response = data
                ? await axios.put(apiEndpoint, formDataObject)
                : await axios.post(apiEndpoint, formDataObject);

            data ? alert('employee Edited') : alert(response.data.message);
            navigate('/view-employee');
            setFormData(initialFormData);
        } catch (error) {
            setErrorMessage('Something went wrong. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <div class="container">
                <div class="row">
                    <div class="col-sm-9 col-md-9 mx-auto">
                        <div class="card card-signin my-5">
                            <div class="card-body">
                                <h4 class="card-title text-center">
                                    Add NC Blog
                                </h4>
                                <form class="form-signin" method="POST">
                                    <div class="form-group">
                                        <label for="email">Name</label>
                                        <input
                                            id="title"
                                            type="text"
                                            class="form-control"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div class="form-group">
                                        <label for="email">Location</label>
                                        <textarea
                                            id="description"
                                            type="text"
                                            class="form-control"
                                            name="location"
                                            value={formData.location}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div class="form-group">
                                        <label for="email">Post</label>
                                        <textarea
                                            id="description"
                                            type="text"
                                            class="form-control"
                                            name="post"
                                            value={formData.post}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div class="form-group">
                                        <label for="image"> Image</label>
                                        <input
                                            id="files"
                                            type="file"
                                            class="form-control"
                                            name="profileImage"
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>

                                    <div class="form-group no-margin">
                                        <button
                                            type="button"
                                            class="btn btn-info btn-block"
                                            onClick={handleSubmit}>
                                            {loading ? 'Adding...' : 'Submit'}
                                        </button>
                                    </div>
                                </form>

                                {errorMessage && (
                                    <p style={{ color: 'red' }}>
                                        {errorMessage}
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmployeeAdd;
