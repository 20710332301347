import React, { Component } from 'react';
import './header.css';

class Header extends Component {
    constructor(propos) {
        super();
    }

    render() {
        return (
            <div className='header-top container-fluid' >
              <h1> <i class="fas fa-box"></i>   Admin Dashboard</h1>
            </div>
        );

    }
}

export default Header;