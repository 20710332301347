import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { API_URL } from '../url';

const useStyles = makeStyles({
    root: {},
});

export default function ImgMediaCard(props) {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardActionArea>
                <CardMedia
                    component="img"
                    alt="Image"
                    height="140"
                    image={props.titleImage}
                    title="Image"
                />
                <CardContent>
                    <p className="h-75 overflow-hidden text-nowrap mb-0">
                        {props.title}
                    </p>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
