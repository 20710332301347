import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../url';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import { Table, Button } from 'react-bootstrap';

const GetAllCareerDetails = () => {
    const [careerDetails, setCareerDetails] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const itemsPerPage = 10;
    const fetchCareerDetails = async () => {
        try {
            const token = localStorage.getItem('cxtoken');
            const response = await axios.get(`${API_URL}career/`, {
                headers: { 'x-access-token': token },
            });

            const fetchedCareerDetails = response.data.data;
            setCareerDetails(fetchedCareerDetails);
        } catch (error) {
            console.error('Error fetching career details:', error);
        }
    };
    useEffect(() => {
        fetchCareerDetails();
    }, []);

    const pageCount = Math.ceil(careerDetails.length / itemsPerPage);

    const handlePageChange = ({ selected }) => {
        setPageNumber(selected);
    };

    const displayCareerDetails = careerDetails
        .slice(pageNumber * itemsPerPage, (pageNumber + 1) * itemsPerPage)
        .map((career) => (
            <tr key={career.id}>
                <td>{career.jobName}</td>
                <td>{career.work}</td>
                <td>{career.experience}</td>
                {/* Add other career details here */}
                <td style={{ width: '20%' }}>
                    <Link
                        to={`/edit-career/${career?._id}`}
                        state={{ data: career }}>
                        <Button variant="primary">Edit</Button>
                    </Link>{' '}
                    &nbsp;&nbsp;
                    <Button
                        variant="danger"
                        onClick={(e) =>
                            window.confirm(
                                `Are you sure you want to delete this job details?`,
                            ) && handleDeleteCareer(career._id)
                        }>
                        Delete
                    </Button>
                </td>
            </tr>
        ));

    const handleDeleteCareer = async (careerId) => {
        try {
            const token = localStorage.getItem('cxtoken');
            await axios.delete(`${API_URL}career/${careerId}`, {
                headers: { 'x-access-token': token },
            });

            // Reload the career details after deletion
            fetchCareerDetails();
        } catch (error) {
            console.error('Error deleting career:', error);
        }
    };

    return (
        <div className="container mt-4">
            <div className="card">
                <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between">
                        <h4 className="card-title text-center mb-4">
                            All Career Details
                        </h4>
                        <div className="text-center ">
                            <Link to="/add-career">
                                <Button variant="success">
                                    Add JobDetails
                                </Button>
                            </Link>
                        </div>
                    </div>

                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th>Job Title</th>
                                {/* Add other table headers here */}
                                <th>Work Type</th>
                                <th>Experience</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>{displayCareerDetails}</tbody>
                    </Table>
                    <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageChange}
                        containerClassName={'pagination justify-content-center'}
                        activeClassName={'active'}
                        previousLinkClassName={'page-link'}
                        nextLinkClassName={'page-link'}
                        breakLinkClassName={'page-link'}
                        pageLinkClassName={'page-link'}
                    />
                </div>
            </div>
        </div>
    );
};

export default GetAllCareerDetails;
