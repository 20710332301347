import React, { useState } from 'react';
import QuillEditor from '../Editor/quill';
import axios from 'axios';
import { API_URL } from '../url';
import { useNavigate } from 'react-router-dom';

const AddJobDetails = () => {
    const navigate = useNavigate();
    const [jobDetails, setJobDetails] = useState({
        jobName: '',
        location: '',
        experience: '',
        work: '',
        formLink: '',
        description: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setJobDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const token = localStorage.getItem('cxtoken');
            await axios.post(API_URL + 'career', jobDetails, {
                headers: { 'x-access-token': token },
            });

            alert('Job Details Added');
            navigate('/careers');
            // Redirect or handle success as needed
        } catch (error) {
            console.error('Error adding job details:', error);
        }
    };

    const onEditorChange = (jobJd) => {
        setJobDetails((prevDetails) => ({
            ...prevDetails,
            description: jobJd,
        }));
    };
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-8 offset-md-2">
                    <div className="card mt-5 mb-3">
                        <div className="card-body">
                            <h4 className="card-title text-center">
                                Add Job Details
                            </h4>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group mt-2">
                                    <label htmlFor="jobName">Job Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="jobName"
                                        value={jobDetails.jobName}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group mt-2">
                                    <label htmlFor="location">Location</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="location"
                                        placeholder="location"
                                        value={jobDetails.location}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>

                                <div className="form-group mt-2">
                                    <label htmlFor="experience">
                                        Experience
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="experience"
                                        placeholder="0-2 year"
                                        value={jobDetails.experience}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group mt-2">
                                    <label htmlFor="work">Work</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="full-time"
                                        name="work"
                                        value={jobDetails.work}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group mt-2">
                                    <label htmlFor="formLink">Form Link</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="formLink"
                                        value={jobDetails.formLink}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group mt-2 mb-3">
                                    <label htmlFor="description">
                                        Description
                                    </label>
                                    <QuillEditor
                                        placeholder={'Start Posting Something'}
                                        onEditorChange={onEditorChange}
                                    />
                                </div>
                                <div className="form-group text-center">
                                    <button
                                        type="submit"
                                        className="btn btn-info">
                                        Add Job Details
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddJobDetails;
