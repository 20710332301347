import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storageSession from 'reduxjs-toolkit-persist/lib/storage/session';
import userReducer from './userReducer';

const userPersistConfig = {
    key: 'user',
    storage: storageSession,
};

export default combineReducers({
    userRoot: persistReducer(userPersistConfig, userReducer),
});
