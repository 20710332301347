import React from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { API_URL, API_HOST } from '../services/url';
import axios from 'axios';
import './quill.css';
const __ISMSIE__ = navigator.userAgent.match(/Trident/i) ? true : false;

const Font = ReactQuill.Quill.import('formats/font');
Font.whitelist = [
    'ubuntu',
    'verdana',
    'roboto',
    'impact',
    'courier',
    'comic',
    'times-new-roman',
];
ReactQuill.Quill.register(Font, true);

var Size = ReactQuill.Quill.import('attributors/style/size');
Size.whitelist = [
    '10px',
    '12px',
    '14px',
    '16px',
    '18px',
    '20px',
    '22px',
    '24px',
];
ReactQuill.Quill.register(Size, true);

// Quill.register('modules/clipboard', PlainClipboard, true);

const QuillClipboard = Quill.import('modules/clipboard');

class Clipboard extends QuillClipboard {
    getMetaTagElements = (stringContent) => {
        const el = document.createElement('div');
        el.innerHTML = stringContent;
        return el.getElementsByTagName('meta');
    };

    async onPaste(e) {
        let clipboardData = e.clipboardData || window.clipboardData;
        let pastedData = await clipboardData.getData('Text');

        const urlMatches = pastedData.match(/\b(http|https)?:\/\/\S+/gi) || [];
        if (urlMatches.length > 0) {
            e.preventDefault();
            urlMatches.forEach((link) => {
                axios
                    .get(link)
                    .then((payload) => {
                        // let title, image, url, description;
                        let title, image, url;
                        for (let node of this.getMetaTagElements(payload)) {
                            if (node.getAttribute('property') === 'og:title') {
                                title = node.getAttribute('content');
                            }
                            if (node.getAttribute('property') === 'og:image') {
                                image = node.getAttribute('content');
                            }
                            if (node.getAttribute('property') === 'og:url') {
                                url = node.getAttribute('content');
                            }
                            // if (node.getAttribute("property") === "og:description") {
                            //     description = node.getAttribute("content");
                            // }
                        }

                        const rendered = `<a href=${url} target="_blank"><div><img src=${image} alt=${title} width="20%"/><span>${title}</span></div></a>`;

                        let range = this.quill.getSelection();
                        let position = range ? range.index : 0;
                        this.quill.pasteHTML(position, rendered, 'silent');
                        this.quill.setSelection(position + rendered.length);
                    })
                    .catch((error) => console.error(error));
            });
        } else {
            //console.log('when to use this') 보통 다른 곳에서  paste 한다음에  copy하면 이쪽 걸로 한다.
            super.onPaste(e);
        }
    }
}
Quill.register('modules/clipboard', Clipboard, true);

const BlockEmbed = Quill.import('blots/block/embed');

class ImageBlot extends BlockEmbed {
    static create(value) {
        const imgTag = super.create();
        imgTag.setAttribute('src', value.src);
        imgTag.setAttribute('alt', value.alt);
        imgTag.setAttribute('width', '100%');
        return imgTag;
    }

    static value(node) {
        return { src: node.getAttribute('src'), alt: node.getAttribute('alt') };
    }
}

ImageBlot.blotName = 'image';
ImageBlot.tagName = 'img';
Quill.register(ImageBlot);

class VideoBlot extends BlockEmbed {
    static create(value) {
        if (value && value.src) {
            const videoTag = super.create();
            videoTag.setAttribute('src', value.src);
            videoTag.setAttribute('title', value.title);
            videoTag.setAttribute('width', '100%');
            videoTag.setAttribute('height', '500');
            videoTag.setAttribute('controls', '');

            return videoTag;
        } else {
            const iframeTag = document.createElement('iframe');
            iframeTag.setAttribute('src', value);
            iframeTag.setAttribute('frameborder', '0');
            iframeTag.setAttribute('allowfullscreen', true);
            iframeTag.setAttribute('width', '100%');
            iframeTag.setAttribute('height', '500');
            return iframeTag;
        }
    }

    static value(node) {
        if (node.getAttribute('title')) {
            return {
                src: node.getAttribute('src'),
                alt: node.getAttribute('title'),
            };
        } else {
            return node.getAttribute('src');
        }
        // return { src: node.getAttribute('src'), alt: node.getAttribute('title') };
    }
}

VideoBlot.blotName = 'video';
VideoBlot.tagName = 'video';
Quill.register(VideoBlot);

class FileBlot extends BlockEmbed {
    static create(value) {
        const prefixTag = document.createElement('span');
        prefixTag.innerText = '첨부파일 - ';

        const bTag = document.createElement('b');
        //위에 첨부파일 글자 옆에  파일 이름이 b 태그를 사용해서 나온다.
        bTag.innerText = value;

        const linkTag = document.createElement('a');
        linkTag.setAttribute('href', value);
        linkTag.setAttribute('target', '_blank');
        linkTag.setAttribute('className', 'file-link-inner-post');
        linkTag.appendChild(bTag);
        //linkTag 이런식으로 나온다 <a href="btn_editPic@3x.png" target="_blank" classname="file-link-inner-post"><b>btn_editPic@3x.png</b></a>

        const node = super.create();
        node.appendChild(prefixTag);
        node.appendChild(linkTag);

        return node;
    }

    static value(node) {
        const linkTag = node.querySelector('a');
        return linkTag.getAttribute('href');
    }
}

FileBlot.blotName = 'file';
FileBlot.tagName = 'p';
FileBlot.className = 'file-inner-post';
Quill.register(FileBlot);

class PollBlot extends BlockEmbed {
    static create(value) {
        const prefixTag = document.createElement('span');
        prefixTag.innerText = '투표 - ';

        const bTag = document.createElement('b');
        bTag.innerText = value.title;

        const node = super.create();
        node.setAttribute('id', value.id);
        node.appendChild(prefixTag);
        node.appendChild(bTag);

        return node;
    }

    static value(node) {
        const id = node.getAttribute('id');
        const bTag = node.querySelector('b');
        const title = bTag.innerText;
        return { id, title };
    }
}

PollBlot.blotName = 'poll';
PollBlot.tagName = 'p';
PollBlot.className = 'poll-inner-post';
Quill.register(PollBlot);

class QuillEditor extends React.Component {
    bandId;
    placeholder;
    onEditorChange;
    onFilesChange;
    onPollsChange;
    _isMounted;

    constructor(props) {
        super(props);

        this.state = {
            editorHtml: __ISMSIE__ ? '<p>&nbsp;</p>' : '',
            files: [],
        };

        this.reactQuillRef = null;

        this.inputOpenImageRef = React.createRef();
        this.inputOpenVideoRef = React.createRef();
        this.inputOpenFileRef = React.createRef();
    }

    componentDidMount() {
        this._isMounted = true;

        this.setState({ editorHtml: this.props.value });
    }
    componentDidUpdate = (prevProps) => {
        if (prevProps != this.props && this.props.value)
            this.setState({ editorHtml: this.props.value });
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleChange = (html) => {
        this.setState(
            {
                editorHtml: html,
            },
            () => {
                this.props.onEditorChange(this.state.editorHtml);
            },
        );
    };

    render() {
        return (
            <div>
                <div id="toolbar">
                    <select
                        className="ql-header"
                        defaultValue={''}
                        onChange={(e) => e.persist()}>
                        <option value="1" />
                        <option value="2" />
                        <option value="" />
                    </select>
                    <button className="ql-bold" />
                    <button className="ql-italic" />
                    <button className="ql-underline" />
                    <button className="ql-strike" />
                    <select
                        className="ql-font"
                        defaultValue={'times-new-roman'}
                        onChange={(e) => e.persist()}
                        style={{ width: '180px' }}>
                        <option value="times-new-roman">Times New Roman</option>
                        <option value="roboto">Roboto</option>
                        <option value="verdana">Verdana</option>
                        <option value="ubuntu">Ubuntu</option>
                        <option value="comic">Cosmic</option>
                        <option value="courier">Courier</option>
                        <option value="impact">Impact</option>
                    </select>
                    <button className="ql-link" />
                    <button className="ql-code-block" />
                    <button className="ql-blockquote" />
                </div>
                <ReactQuill
                    ref={(el) => {
                        this.reactQuillRef = el;
                    }}
                    theme={'snow'}
                    onChange={this.handleChange}
                    modules={this.modules}
                    formats={this.formats}
                    value={this.state.editorHtml}
                    placeholder={this.props.placeholder}
                />
            </div>
        );
    }

    modules = {
        toolbar: {
            container: '#toolbar',
            handlers: {
                insertPoll: this.pollHandler,
            },
        },
    };
    formats = [
        'header',
        'bold',
        'italic',
        'underline',
        'strike',
        'font',
        'size',
        'image',
        'video',
        'file',
        'link',
        'code-block',
        'video',
        'blockquote',
        'clean',
    ];
}

export default QuillEditor;
