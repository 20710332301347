import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './sidebar.css';
import Login from '../login/login';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
    }

    async onSubmit(e) {
        e.preventDefault();
        this.clearAuthData();
        window.location.reload();
        window.location.replace('/');
    }

    clearAuthData() {
        localStorage.removeItem('cxtoken');
        localStorage.removeItem('expiration');
    }

    render() {
        return (
            <div class="sidenav">
                <a
                    data-toggle="collapse"
                    className="text-white blog_tag "
                    data-target="#submenu-5">
                    NC Blogs <FontAwesomeIcon icon={faAngleDown} />
                </a>

                <ul id="submenu-5" class="collapse list-unstyled blog_tag_list">
                    <li>
                        <Link to="/NCAddBlog">
                            <FontAwesomeIcon icon={faAngleRight} /> Add
                        </Link>
                    </li>
                    <li>
                        <Link to="/">
                            <FontAwesomeIcon icon={faAngleRight} /> View
                        </Link>
                    </li>
                </ul>
                <Link className="text-white blog_tag" to="/inquiries">
                    Inquiries
                </Link>
                <Link className="text-white blog_tag" to="/view-employee">
                    Employee
                </Link>
                <a
                    data-toggle="collapse"
                    class="text-white blog_tag"
                    data-target="#submenu-6">
                    NC Projects <FontAwesomeIcon icon={faAngleDown} />
                </a>
                <ul id="submenu-6" class="collapse list-unstyled blog_tag_list">
                    <li>
                        <Link to="/add_nc_project">
                            <FontAwesomeIcon icon={faAngleRight} /> Add
                        </Link>
                    </li>
                    <li>
                        <Link to="/nc_projects">
                            <FontAwesomeIcon icon={faAngleRight} /> View
                        </Link>
                    </li>
                </ul>
                <a
                    data-toggle="collapse"
                    className="text-white blog_tag"
                    data-target="#submenu-7">
                    Career <FontAwesomeIcon icon={faAngleDown} />
                </a>
                <ul
                    id="submenu-7"
                    className="collapse list-unstyled blog_tag_list">
                    <li>
                        <Link to="/add-Career">
                            <FontAwesomeIcon icon={faAngleRight} /> Add Career
                        </Link>
                    </li>

                    <li>
                        <Link to="/careers">
                            <FontAwesomeIcon icon={faAngleRight} /> View
                        </Link>
                    </li>
                </ul>
                <a
                    href="/"
                    class="text-white logout"
                    onClick={(e) =>
                        window.confirm(`Are you sure you want to logout?`) &&
                        this.onSubmit(e)
                    }>
                    Logout
                </a>
            </div>
        );
    }
}

export default Sidebar;
