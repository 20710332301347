import React, { useEffect, useState } from 'react';
// import '../UserEngagement/user_register.css';
import axios from 'axios';
import { API_URL } from '../url';
import ReactPaginate from 'react-paginate';
import './inquiries.css';
const Inquiries = () => {
    const [inquiries, setInquiries] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const itemsPerPage = 10; // Adjust the number of items per page as needed

    useEffect(() => {
        getData();
    }, [pageNumber]); // Ensure useEffect depends on pageNumber

    const getData = async () => {
        try {
            const response = await axios.get(`${API_URL}contactUs`, {
                params: {
                    page: pageNumber + 1, // Adjust the API to handle pagination
                    // other params if needed
                },
            });
            console.log('first', response.data);
            setInquiries(response.data);
        } catch (error) {
            console.error('Error fetching inquiries:', error);
        }
    };

    const pageCount = Math.ceil(inquiries?.length / itemsPerPage);

    const handlePageChange = ({ selected }) => {
        setPageNumber(selected);
    };

    return (
        <div>
            <div className=" ">
                <div className="container">
                    <div className="page-header text-center">
                        <h3>Inquiries</h3>
                    </div>
                    <div className="tableFixHead">
                        <table className="table table-bordered text-center table-hover">
                            <thead className="thead-grey">
                                <tr>
                                    <th scope="col">S.no</th>
                                    <th scope="col">FirstName</th>
                                    <th scope="col">LastName</th>
                                    <th scope="col">Email</th>

                                    <th scope="col">Message</th>
                                </tr>
                            </thead>
                            <tbody>
                                {inquiries &&
                                    inquiries.map((item, key) => (
                                        <tr>
                                            <td>{key + 1}</td>
                                            <td>
                                                {item.firstName
                                                    ? item.firstName
                                                    : 'N/A'}
                                            </td>
                                            <td>{item.lastName}</td>
                                            <td>{item.email}</td>

                                            <td className="message">
                                                {item.message}
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                    <ReactPaginate
                        pageCount={pageCount} // Replace with your actual number of pages
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageChange}
                        containerClassName="pagination-container"
                        pageClassName="pagination-item"
                        breakClassName="pagination-item"
                        previousClassName="pagination-item"
                        nextClassName="pagination-item"
                        activeClassName="pagination-item active"
                    />
                </div>
            </div>
        </div>
    );
};

export default Inquiries;
