import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { API_URL } from '../url';
function PostPage(props) {
    const params = useParams();

    const [post, setPost] = useState([]);

    useEffect(() => {
        getBlogData();
    }, []);
    const getBlogData = async () => {
        const response = await axios.get(
            `${API_URL}project/byId/${params.postId}`,
        );
        console.log(response);
        if (response.data.success) {
            setPost(response.data?.data?.at(0));
        }
    };
    document.title =
        'View Blog | Persona | Empathy | Value | Journey Mapping | Innovation | Kanban | CXDeployer';

    return (
        <div className="container">
            <div
                className="postPage"
                style={{ width: '80%', margin: '2rem auto' }}>
                <Link to="/nc_projects">
                    <button className="btn btn-primary">
                        go back to project page
                    </button>
                </Link>
                <br />
                <br />
                <h1 className="mb-5">{post.title}</h1>{' '}
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}></div>
                <div dangerouslySetInnerHTML={{ __html: post.blog_content }} />
            </div>
        </div>
    );
}

export default PostPage;
