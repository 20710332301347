import React, { useState, useEffect } from 'react';
import Card from './Card';
import { getBlogs } from './Api';
import axios from 'axios';
import { API_URL } from '../url';
import { Link } from 'react-router-dom';

export default function AllBlogs() {
    const [loading, setloading] = useState(true);
    const [data, setdata] = useState([]);

    const fetchBlogs = () => {
        getBlogs()
            .then((res) => {
                if (res.success) {
                    setdata(res.data);
                }
            })
            .catch((error) => console.log(error))
            .finally(() => setloading(false));
    };
    const handleDelete = (e) => {
        e.preventDefault();
        const id = e.target.id;
        const name = e.target.name;
        const token = localStorage.getItem('cxtoken');
        axios
            .delete(API_URL + `project/${id}`, {
                headers: { 'x-access-token': token },
            })
            .then((res) => {
                console.log(res);
                window.location.reload(false);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        fetchBlogs();
    }, []);
    document.title =
        'Blogs | Persona | Empathy | Value | Journey Mapping | Innovation | Kanban | CXDeployer';
    return (
        <div className="pt-5">
            <div className="container">
                <div className="container text-center">
                    <h1>All NC Projects</h1>
                </div>
                {loading ? (
                    <div className="row mt-5 text-center align-content-center d-flex justify-content-center">
                        <div
                            className="spinner-grow text-primary"
                            style={{ height: '100px', width: '100px' }}
                            role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <div
                        className="row mt-5"
                        style={{
                            marginBottom: '15px',
                            height: '450px',
                        }}>
                        {data.map((blog) => {
                            return (
                                <div
                                    class="col-lg-4 "
                                    style={{
                                        marginBottom: '5px',
                                        height: '450px',
                                    }}>
                                    <div className="card p-2 gap-2">
                                        <Card
                                            title={blog.title}
                                            titleImage={blog.titleImage}
                                        />
                                        <Link
                                            className="btn btn-dark"
                                            to={{
                                                pathname: `/view_nc_project/${blog._id}`,
                                                blog: blog,
                                            }}>
                                            Read this project
                                        </Link>
                                        <Link
                                            className="btn btn-dark mt-2"
                                            to={{
                                                pathname: `/edit_nc_project/${blog._id}`,
                                                blog: blog,
                                            }}>
                                            Edit
                                        </Link>

                                        <Link
                                            className="btn btn-danger mt-2"
                                            name={blog.image}
                                            id={blog._id}
                                            onClick={(e) =>
                                                window.confirm(
                                                    `Are you sure you want to delete this article?`,
                                                ) && handleDelete(e)
                                            }>
                                            Delete this project
                                        </Link>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
}
