import React, { Component } from 'react';
import './App.css';
import {
    Link,
    Route,
    BrowserRouter as Router,
    Navigate,
    Routes,
} from 'react-router-dom';
import Login from './login/login';
// import Signup from './signup/signup';
import ViewNcBlog from './nc_blog/viewNcBlogs';
import NCAllBlogs from './nc_blog/allNCBlogs';
import NCEditBlog from './nc_blog/add_blog/nc_edit_blog';
import NCAddBlog from './nc_blog/add_blog/nc_add_blog';

import ViewNcProject from './nc_projects/viewNcProjects';
import AllNCProjects from './nc_projects/allNCProjects';
import EditNCProject from './nc_projects/add_nc_projects/nc_edit_projects';
import AddNCProject from './nc_projects/add_nc_projects/nc_add_projects';

import AddCareer from './careers/addCareer';
import EditCareer from './careers/editCareer';
import ViewCareer from './careers/viewCareer';
import Inquiries from './inquiries/inquiries';
import Header from './header/header';
import Sidebar from './Sidebar/Sidebar';
import EmployeeAdd from './employee/employeeAdd';
import ViewAllEmployee from './employee/viewAllEmployee';
class App extends Component {
    constructor() {
        super();
        this.state = {
            route: 'signin',
            isSignedIn: false,
            logginStatus: true,
        };
    }
    onRouteChange = (route) => {
        if (route === 'signout') {
            this.setState({ isSignedIn: false });
        } else if (route === 'home') {
            this.setState({ isSignedIn: true });
        }
        this.setState({ route: route });
    };

    render() {
        return (
            <Router>
                <div>
                    {localStorage.getItem('cxtoken') ? (
                        <div>
                            <Header />
                            <Sidebar />
                            <main
                                className="content"
                                style={{ marginLeft: '200px' }}>
                                <Routes>
                                    {/** routes for nc blogs */}

                                    <Route
                                        exact
                                        path="/"
                                        element={<NCAllBlogs />}
                                    />
                                    <Route
                                        exact
                                        path="/add-employee"
                                        element={<EmployeeAdd />}
                                    />

                                    <Route
                                        path="/edit/:id"
                                        element={<EmployeeAdd />}
                                    />
                                    <Route
                                        path="/view-employee"
                                        element={<ViewAllEmployee />}
                                    />
                                    <Route
                                        exact
                                        path="/NCAddBlog"
                                        element={<NCAddBlog />}
                                    />
                                    <Route
                                        exact
                                        path={'/nc_editblog/:postId'}
                                        element={<NCEditBlog />}
                                    />
                                    <Route
                                        exact
                                        path={'/viewnc_blog/:postId'}
                                        element={<ViewNcBlog />}
                                    />
                                    {/** routes for ncProjects */}

                                    <Route
                                        exact
                                        path="/nc_projects"
                                        element={<AllNCProjects />}
                                    />
                                    <Route
                                        exact
                                        path="/add_nc_project"
                                        element={<AddNCProject />}
                                    />
                                    <Route
                                        exact
                                        path={'/edit_nc_project/:postId'}
                                        element={<EditNCProject />}
                                    />

                                    <Route
                                        exact
                                        path={'/view_nc_project/:postId'}
                                        element={<ViewNcProject />}
                                    />
                                    <Route
                                        exact
                                        path={'/add-career'}
                                        element={<AddCareer />}
                                    />

                                    <Route
                                        exact
                                        path={'/edit-career/:id'}
                                        element={<EditCareer />}
                                    />
                                    <Route
                                        exact
                                        path={'/careers'}
                                        element={<ViewCareer />}
                                    />

                                    {/* <Route
                                    exact
                                    path={'/user_register'}
                                    element={<UserRegister />}
                                /> */}
                                    <Route
                                        exact
                                        path={'/inquiries'}
                                        element={<Inquiries />}
                                    />
                                </Routes>
                            </main>
                        </div>
                    ) : (
                        <Routes>
                            <Route exact path={'/'} element={<Login />} />
                            {/* <Route
                                exact
                                path={'/create-admin'}
                                element={<Signup />}
                            /> */}
                        </Routes>
                    )}
                </div>
            </Router>
        );
    }
}

export default App;
